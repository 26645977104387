export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/login": [4],
		"/login/authorize": [5],
		"/login/mfa": [6],
		"/onboarding": [7],
		"/onboarding/authorize": [8],
		"/onboarding/guide/[id]": [9],
		"/onboarding/quiz/[id]": [10],
		"/onboarding/resp/[id]": [11],
		"/panel": [12,[2]],
		"/panel/apps": [13,[2]],
		"/panel/blog": [14,[2]],
		"/panel/bots/queue": [15,[2]],
		"/panel/bots/whitelist": [16,[2]],
		"/panel/cdn": [17,[2]],
		"/panel/cdn/[scope]": [18,[2]],
		"/panel/changelogs": [19,[2]],
		"/panel/logout": [20,[2]],
		"/panel/partners": [21,[2]],
		"/panel/rpc/[targetType]": [22,[2]],
		"/panel/settings": [23,[2]],
		"/panel/shop/benefits": [24,[2]],
		"/panel/shop/coupons": [25,[2]],
		"/panel/shop/items": [26,[2]],
		"/panel/staffguide": [32,[2]],
		"/panel/staff": [27,[2]],
		"/panel/staff/disciplinary_types": [28,[2]],
		"/panel/staff/members": [29,[2]],
		"/panel/staff/positions": [30,[2]],
		"/panel/staff/rpclogs": [31,[2]],
		"/panel/vote_credit_tiers": [33,[2]],
		"/test": [34]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';